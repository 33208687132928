<template>
  <b-card>
    <div class="d-flex justify-content-between align-items-center w-100 mb-1">
      <h3>حول المنارة</h3>
      <div>
        <b-button variant="primary" v-b-tooltip.hover.v-info.top v-b-toggle.collapse-2 @click="onAdd">
          <feather-icon icon="editIcon" /> إضافة </b-button>
      </div>
    </div>
    <hr />
    <div>
      <div>
        <data-table ref="estatesTable" :fields="fields" ep="/abouts">
          <template #cell(actions)="row">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>

              <b-dropdown-item @click="onUpdateClicked(row.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">تعديل</span>
              </b-dropdown-item>

              <b-dropdown-item @click="onDelete(row.item)">
                <feather-icon icon="Trash2Icon" />
                <span class="align-middle ml-50">حذف</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

        </data-table>

      </div>

    </div>
    <form-modal ref="estateModalAboutContent" :formSchema="estatesFormSchema" title="إضافة" v-model="activModel"
      :loading="loading" @confirm="onModalConfirmed">
    </form-modal>
  </b-card>
</template>


<script>
import {
  BCard,
  BButton,
  VBTooltip,
  BSkeleton,
  BCollapse,
  VBToggle,
  BForm, BFormGroup, BFormInput, BRow, BCol, BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import { heightTransition } from '@core/mixins/ui/transition'
import FormQuill from "@/components/form-quill/index.vue";
import LoaderBtn from "@/components/loader-btn.vue";
import DataTable from "@/components/data-table/index.vue";
import FormModal from "@/components/FormModal.vue";
export default {
  components: {
    BCard,
    BButton,
    BSkeleton,
    VBTooltip,
    BCollapse,
    VBToggle,
    FormQuill, LoaderBtn, BForm, BFormGroup, BFormInput, BRow, BCol, DataTable, BDropdown,
    BDropdownItem,FormModal
  },
  mixins: [heightTransition],
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      activModel: false,
      fields: [
        { key: "title", label: "العنوان" },
        { key: "content", label: "المحتوى" },
        { key: "actions", label: "" }
      ],

      estatesFormSchema: {
        title: {
          component: "FormInput",
          key: "title",
          attrs: {
            id: "first_name",
            label: "العنوان",
            rules: "required",
          },
        },
        content: {
          component: "FormTextarea",
          key: "content",
          attrs: {
            label: "المحتوى",
            rules: "required",
          },
        },
      }
    };
  },
 
  methods: {
    ...mapActions("settings/aboutManaraApp", ["getAbout", "updateAboutContent", "deletAboutContent", "createAboutContent"]),
    onAdd() {
      this.$refs.estateModalAboutContent.init({});
      this.activModel = true;
    },
    onDelete(item) {
      // console.log(id)
      this.$bvModal
        .msgBoxConfirm("هل أنت متأكد من حذف المحتوى", {
          title: "تأكيد حذف المحتوى",
          size: "sm",
          okVariant: "danger",
          okTitle: "تأكيد",
          cancelTitle: "إلغاء",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value)
            this.deletAboutContent({ id: item.id }).then(() => {
              this.$refs.estatesTable.refreshTable();
            });
        });
    },
    onUpdateClicked(form) {
      var toUpdate = {
        id: form.id,
        content: form.content,
        title: form.title
      };
      this.$refs.estateModalAboutContent.init(toUpdate);
      this.activModel = true;
    },
    onModalConfirmed(form) {
console.log(form)
      if (form.id) {
        this.updateAboutContent({ id: form.id, data: form }).then(() => {
          this.$refs.estatesTable.refreshTable();
          this.activModel = false;
        });
      } else
        this.createAboutContent({ data: form }).then(() => {
          this.$refs.estatesTable.refreshTable();
          this.activModel = false;
        });
    },

  },
  computed: {
    ...mapGetters("settings/aboutManaraApp", ["about", "loading"]),
  },
  watch: {
    about(val) {
      this.aboutText = val[1].content
    }
  },

  created() {
    this.getAbout();
  },
};
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>